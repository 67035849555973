import core from '@/admin/core';

export interface OfficialUser {
  officialUserId: string;
  name: string;
  icon: string;
  color: string;
  isDeleted: boolean;
  createDate?: number;
}

export interface OfficialUserProp {
  name: string;
  icon: string;
  color: string;
}

/**
 * 公式ユーザー一覧取得API
 *
 * @returns 公式ユーザー一覧
 */
export const getOfficialUsers = async (): Promise<OfficialUser[]> => {
  const result = await core.httpClient.get('/admin/public/officialUsers');
  return result.data as OfficialUser[];
};

export const createOfficialUser = async (props: OfficialUserProp) => {
  await core.httpClient.post('/admin/public/officialUsers', props);
};

export const editOfficialUser = async (props: OfficialUserProp, officialUserId: string) => {
  await core.httpClient.put(`/admin/public/officialUsers/${officialUserId}`, props);
};
