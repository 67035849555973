import { computed, Ref, ref } from '@vue/composition-api';
import { getOptionsTitle } from '@/admin/util';
import {
  createOfficialUser,
  editOfficialUser,
  getOfficialUsers,
  OfficialUser,
  OfficialUserProp,
} from '@/admin/officialUsers';
import myAttributes from '@/composition/myAttributes';

export class OfficialUsers {
  private _fetchLoading = ref(false);
  private _officialUsers: Ref<OfficialUser[]> = ref([]);
  private _officialUserOptions = computed(() => {
    return this._officialUsers.value.map((user) => {
      return { text: user.name, value: user.officialUserId };
    });
  });

  get fetchLoading() {
    return this._fetchLoading.value;
  }
  get officialUsers() {
    return this._officialUsers.value;
  }
  set officialUsers(_officialUsers: OfficialUser[]) {
    this._officialUsers.value = _officialUsers;
  }

  constructor() {
    this.getOfficialUsers();
  }

  getOfficialUsers = async () => {
    this._fetchLoading.value = true;
    try {
      const _officialUsers = await getOfficialUsers();
      // createDateがないuserにはcreateDateを追加しソートできるようにする。（現在日時+1s）
      const now = new Date();
      const defaultCreateDate = now.setSeconds(now.getSeconds() + 1);
      this.officialUsers = _officialUsers
        // 削除フラグがあるユーザーをフィルター
        .filter((user) => !user.isDeleted)
        .map((user) => {
          if (!user.createDate) user.createDate = defaultCreateDate;
          return user;
        })
        .sort((a, b) => (a.createDate && b.createDate ? (a.createDate < b.createDate ? -1 : 1) : 0));
    } catch (e) {
      console.error(e);
    }
    this._fetchLoading.value = false;
  };

  /**
   * 公式ユーザーの選択肢を取得する
   *
   * @remarks
   * 規制対象である場合、公式ユーザーの選択肢を紐付け公式ユーザーで絞り込み、
   * 規制対象ではなく、ユーザーを指定しない場合も含む時は、ユーザーを指定しない場合の選択肢も含む
   *
   * @param nouser - ユーザーを指定しない場合を含むかのフラグ
   * @param text - ユーザーを指定しない場合の選択肢表示テキスト
   * @param unrestrict - 規制対象でないかのフラグ
   * @returns 表示する公式ユーザーの選択肢
   */
  getOfficialUserOptions(nouser = true, text = '表示しない', unrestrict = false) {
    const noUserOption = {
      text: text,
      value: 'nouser',
    }; // ユーザーを指定しない場合の選択肢を保持
    const userOptions = this._officialUserOptions.value; // 公式ユーザー一覧取得APIで取得した公式ユーザーの選択肢を保持

    // 公式ユーザーの制限あり
    if (!unrestrict) return userOptions.filter((user) => myAttributes.linkedOfficialUserIds.includes(user.value));
    // 公式ユーザーの制限なし＋表示しないの選択肢をマージ
    else if (unrestrict && nouser) return [noUserOption, ...userOptions];
    // 公式ユーザーの制限なし
    else return userOptions;
  }

  getOfficialUser = (officialUserId: string) => {
    return this._officialUsers.value.find((user) => user.officialUserId === officialUserId);
  };

  getOfficialUserName = (officialUserId: string) => {
    return getOptionsTitle(this._officialUserOptions.value, officialUserId);
  };

  getOfficialUserNames = (officialUserIds: string[]) => {
    return officialUserIds.map((id) => getOptionsTitle(this._officialUserOptions.value, id));
  };

  getOfficialUserColor = (officialUserId: string) => {
    return this._officialUsers.value.find((user) => user.officialUserId === officialUserId)?.color || '';
  };

  getOfficialUserIcon = (officialUserId: string) => {
    return this._officialUsers.value.find((user) => user.officialUserId === officialUserId)?.icon || '';
  };

  createOfficialUser = async (props: OfficialUserProp) => {
    await createOfficialUser(props);
  };

  editOfficialUser = async (props: OfficialUserProp, officialUserId: string) => {
    await editOfficialUser(props, officialUserId);
  };
}

const officialUsers = new OfficialUsers();
export default officialUsers;
