var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0"},[(!_vm.myRoleSettings)?_c('fc-role-loading',{attrs:{"my-role-settings":_vm.myRoleSettings,"page-title":_vm.pageTitle}}):[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.pageTitle))]),_c('v-spacer'),(_vm.myRoleSettings.createAndUpdate)?_c('v-btn',{attrs:{"elevation":"0","color":"primary","to":"/talkroom/create"}},[_vm._v("新しいトークルームを作成")]):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"elevation":"0","color":"primary","to":"/talkroom/setting"}},[_vm._v("マスター管理")])],1),(_vm.myRoleSettings.get)?_c('v-sheet',{attrs:{"outlined":"","rounded":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.talkRooms.items,"items-per-page":-1,"loading":_vm.talkRooms.fetchLoading,"loader-height":"2","hide-default-footer":"","height":_vm.tableHeight},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/talkroom/" + (item.talkRoomId))}},[_vm._v(_vm._s(item.title))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","href":("" + _vm.hostingUrl + (item.url)),"target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])],1)]}}],null,true)},[_c('span',[_vm._v("別タブでページを表示")])]),(_vm.myRoleSettings.getPosts)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","to":("/talkroom/" + (item.talkRoomId) + "/post")}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-card-text ")])],1)]}}],null,true)},[_c('span',[_vm._v("投稿一覧")])]):_vm._e(),(_vm.myRoleSettings.createAndUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","to":{ name: 'CreateTalkRoom', params: { copyTalkRoom: item } }}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-file-multiple ")])],1)]}}],null,true)},[_c('span',[_vm._v("複製")])]):_vm._e(),(_vm.myRoleSettings.delete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":""},on:{"click":function($event){return _vm.deleteRoom(item.talkRoomId)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("削除")])]):_vm._e()]}}],null,true)})],1):_c('fc-role-deny')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }