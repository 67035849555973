import core from '@/admin/core';
import { computed, reactive } from '@vue/composition-api';
import { DisplayDate } from '@/admin/util';
import officialUsers from '@/composition/officialUser';
import myAttributes from '@/composition/myAttributes';

interface Permission {
  acceptAllMembers: boolean;
  acceptAllUsers: boolean;
  deny: boolean;
  planIds?: string[];
  giftIds?: string[];
  productIds?: string[];
  seasonIds?: number[];
}
interface TalkRoomResponse {
  talkRoomId: string;
  title: string;
  thumbnail: string;
  description: string;
  url: string;
  releaseDate: number;
  accessStartDate: number;
  accessEndDate?: number;
  postStartDate: number;
  postEndDate?: number;
  accessAuthority: Permission;
  postAuthority: Permission;
  pinnedPostId: string;
  isDisplayPostedDate: boolean;
  isDeleted: boolean;
  createUser: string;
  createDate: number;
  updateDate: number;
}

interface TalkRoomProps {
  title: string;
  thumbnail?: string;
  description?: string;
  releaseDate: number;
  accessStartDate: number;
  accessEndDate?: number;
  postStartDate: number;
  postEndDate?: number;
  accessAuthority: Permission;
  postAuthority?: Permission;
  isDisplayPostedDate?: boolean;
}

interface BannedWordsProps {
  bannedWords: string;
}

export interface TalkRoomProperty {
  talkRoomId: string;
  title: string;
  thumbnail: string;
  description: string;
  url: string;
  releaseDate: DisplayDate;
  accessStartDate: DisplayDate;
  accessEndDate: DisplayDate;
  postStartDate: DisplayDate;
  postEndDate: DisplayDate;
  accessAuthority: Permission;
  postAuthority: Permission;
  pinnedPostId: string;
  isDisplayPostedDate: boolean;
  createUser: string;
  createDate: DisplayDate;
  updateDate: DisplayDate;
  isAccessEndDate: boolean;
}

interface PostResponse {
  postId: string;
  talkRoomId: string;
  body: string;
  image: string;
  videoId: string;
  postedDate: number;
  postUser: string;
  nickname: string;
  isOfficial: boolean;
  isDeleted: boolean;
  color: string;
  icon: string;
}

interface TalkRoomsProperty {
  talkRoomId: string;
  title: string;
  thumbnail: string;
  description: string;
  url: string;
  releaseDate: DisplayDate;
  accessStartDate: DisplayDate;
  accessEndDate: DisplayDate;
  postStartDate: DisplayDate;
  postEndDate: DisplayDate;
  accessAuthority: Permission;
  postAuthority: Permission;
  pinnedPostId: string;
  isDisplayPostedDate: boolean;
  isDeleted: boolean;
  createUser: string;
  createDate: DisplayDate;
  updateDate: DisplayDate;
}

export interface PostProperty {
  postId: string;
  postUser: string;
  nickname: string;
  body: string;
  postedDate: DisplayDate;
  isOfficial: boolean;
  color: string;
  isDeleted?: boolean;
  image: string;
  videoId: string;
}

export interface PostProps {
  talkRoomId: string;
  body: string;
  image: string;
  videoId: string;
  officialUserId: string;
}

export const postLabels = {
  // talkRoomId: 'トークルームID',
  postUser: 'ユーザーID',
  nickname: 'ニックネーム',
  body: '投稿内容',
  postedDate: '投稿日付',
  postId: '投稿ID',
  isOfficial: '公式ユーザー',
  isDeleted: '削除',
  // image: '画像（公式ユーザー限定）',
  // color: '',
  // icon: '',
};
export class TalkRooms {
  fetchLoading = false;
  items: TalkRoomsProperty[] = [];

  constructor() {
    this.init();
  }
  init = async () => {
    this.fetchLoading = true;
    this.items = await this.getTalkRooms();
    this.fetchLoading = false;
  };
  getTalkRooms = async (): Promise<TalkRoomsProperty[]> => {
    if (!myAttributes.myRequestPermissions?.talkRooms) return [];
    const result = await core.httpClient.get('/admin/public/talkRooms');
    const _items = result.data as TalkRoomResponse[];
    return _items.map((_item) => ({
      talkRoomId: _item.talkRoomId,
      title: _item.title,
      thumbnail: _item.thumbnail,
      description: _item.description,
      url: `/#/talkroom/${_item.talkRoomId}`,
      releaseDate: new DisplayDate(_item.releaseDate),
      accessStartDate: new DisplayDate(_item.accessStartDate),
      accessEndDate: new DisplayDate(_item.accessEndDate),
      postStartDate: new DisplayDate(_item.postStartDate),
      postEndDate: new DisplayDate(_item.postEndDate),
      accessAuthority: _item.accessAuthority,
      postAuthority: _item.postAuthority,
      pinnedPostId: _item.pinnedPostId,
      isDisplayPostedDate: _item.isDisplayPostedDate,
      isDeleted: _item.isDeleted,
      createUser: _item.createUser,
      createDate: new DisplayDate(_item.createDate),
      updateDate: new DisplayDate(_item.updateDate),
    }));
  };
  copy = async (talkRoomId: string) => {
    await core.httpClient.post(`/admin/public/talkRooms/${talkRoomId}`);
  };

  delete = async (talkRoomId: string) => {
    await core.httpClient.delete(`/admin/public/talkRooms/${talkRoomId}`);
    this.items = this.items.filter((item) => talkRoomId !== item.talkRoomId);
  };
}
export class TalkRoomMaster {
  fetchLoading = false;
  bannedWords = '';

  constructor() {
    this.init();
  }

  init = async () => {
    this.fetchLoading = true;
    this.bannedWords = await this.getBannedWords();
    this.fetchLoading = false;
  };

  getBannedWords = async (): Promise<string> => {
    const result = await core.httpClient.get('/admin/public/assets/bannedWords');
    return result.data.bannedWords as string;
  };
  saveSetting = async () => {
    // trimと重複を削除しAPIを叩く
    const bannedWords = this.bannedWords
      .split(',')
      .map((word) => word.trim())
      .filter((word, i, words) => !!word && words.indexOf(word) === i)
      .join();
    const props: BannedWordsProps = {
      bannedWords,
    };
    await core.httpClient.post('/admin/public/assets/bannedWords', props);
    this.bannedWords = bannedWords;
  };
}

export class TalkRoom {
  fetchLoading = false;
  item: TalkRoomProperty = {
    talkRoomId: '',
    title: '',
    thumbnail: '',
    description: '',
    url: '',
    releaseDate: new DisplayDate(),
    accessStartDate: new DisplayDate(),
    accessEndDate: new DisplayDate(),
    postStartDate: new DisplayDate(),
    postEndDate: new DisplayDate(),
    accessAuthority: {
      acceptAllMembers: false,
      acceptAllUsers: false,
      deny: false,
      planIds: [],
      seasonIds: [],
      giftIds: [],
      productIds: [],
    },
    postAuthority: {
      acceptAllMembers: false,
      acceptAllUsers: false,
      deny: false,
      planIds: [],
      seasonIds: [],
      giftIds: [],
      productIds: [],
    },
    pinnedPostId: '',
    isDisplayPostedDate: true,
    createUser: '',
    createDate: DisplayDate.now(),
    updateDate: DisplayDate.now(),
    isAccessEndDate: false,
  };
  props: TalkRoomProps | null = null;

  constructor(talkRoomId?: string) {
    this.init(talkRoomId);
  }

  init = async (talkRoomId: string | undefined) => {
    if (talkRoomId) {
      this.fetchLoading = true;
      this.item = await this.getTalkRoom(talkRoomId);
      this.fetchLoading = false;
    }
  };

  getTalkRoom = async (talkRoomId: string): Promise<TalkRoomProperty> => {
    if (myAttributes.myRequestPermissions?.talkRooms) {
      const result = await core.httpClient.get(`/admin/public/talkRooms/${talkRoomId}`);
      const _item = result.data as TalkRoomResponse;
      return {
        talkRoomId: _item.talkRoomId,
        title: _item.title,
        thumbnail: _item.thumbnail,
        description: _item.description,
        url: `/#/talkroom/${_item.talkRoomId}`,
        releaseDate: new DisplayDate(_item.releaseDate),
        accessStartDate: new DisplayDate(_item.accessStartDate),
        accessEndDate: new DisplayDate(_item.accessEndDate),
        postStartDate: new DisplayDate(_item.postStartDate),
        postEndDate: new DisplayDate(_item.postEndDate),
        accessAuthority: {
          acceptAllMembers: _item.accessAuthority.acceptAllMembers,
          acceptAllUsers: _item.accessAuthority.acceptAllUsers,
          deny: _item.accessAuthority.deny,
          planIds: _item.accessAuthority.planIds || [],
          giftIds: _item.accessAuthority.giftIds || [],
          productIds: _item.accessAuthority.productIds || [],
          seasonIds: _item.accessAuthority.seasonIds || [],
        },
        postAuthority: {
          acceptAllMembers: _item.postAuthority.acceptAllMembers,
          acceptAllUsers: _item.postAuthority.acceptAllUsers,
          deny: _item.postAuthority.deny,
          planIds: _item.postAuthority.planIds || [],
          giftIds: _item.postAuthority.giftIds || [],
          productIds: _item.postAuthority.productIds || [],
          seasonIds: _item.postAuthority.seasonIds || [],
        },
        pinnedPostId: _item.pinnedPostId,
        isDisplayPostedDate: _item.isDisplayPostedDate,
        createUser: _item.createUser,
        createDate: new DisplayDate(_item.createDate),
        updateDate: new DisplayDate(_item.updateDate),
        isAccessEndDate: !!_item.accessEndDate,
      };
    } else return {} as TalkRoomProperty;
  };

  createProps = () => {
    if (
      !this.item.title ||
      !this.item.releaseDate.value ||
      !this.item.accessStartDate.value ||
      !this.item.postStartDate.value
    ) {
      throw 'タイトル, 公開日時, 閲覧開始日, 投稿開始日で未入力があります';
    } else if (
      (!this.item.accessAuthority.acceptAllMembers &&
        !this.item.accessAuthority.acceptAllUsers &&
        !this.item.accessAuthority.planIds?.length &&
        !this.item.accessAuthority.seasonIds?.length &&
        !this.item.accessAuthority.giftIds?.length &&
        !this.item.accessAuthority.productIds?.length) ||
      (!this.item.postAuthority.acceptAllMembers &&
        !this.item.postAuthority.deny &&
        !this.item.postAuthority.planIds?.length &&
        !this.item.postAuthority.seasonIds?.length &&
        !this.item.postAuthority.giftIds?.length &&
        !this.item.postAuthority.productIds?.length)
    ) {
      throw '権限で未入力があります';
    } else if (this.item.isAccessEndDate && this.item.accessEndDate.value === undefined) {
      throw '不正な日時です';
    } else {
      this.props = {
        title: this.item.title,
        thumbnail: this.item.thumbnail || '',
        description: this.item.description || '',
        releaseDate: this.item.releaseDate.value,
        accessStartDate: this.item.accessStartDate.value,
        postStartDate: this.item.postStartDate.value,
        postEndDate: this.item.postEndDate?.value,
        accessAuthority: this.item.accessAuthority,
        postAuthority: this.item.postAuthority,
        isDisplayPostedDate: this.item.isDisplayPostedDate,
      };
      if (this.item.isAccessEndDate && this.item.accessEndDate.value)
        this.props.accessEndDate = this.item.accessEndDate.value;
    }
  };

  saveTalkRoom = async (talkRoomId: string) => {
    this.createProps();
    if (!this.props) return;
    await core.httpClient.put(`/admin/public/talkRooms/${talkRoomId}`, this.props);
  };

  createTalkRoom = async () => {
    this.createProps();
    if (!this.props) return;
    await core.httpClient.post('/admin/public/talkRooms', this.props);
  };

  copyTalkRoom = (props: TalkRoomProperty) => {
    this.item = props;
    this.item.title = `【アイテム複製】${this.item.title}`;
    this.item.isAccessEndDate = !!props.accessEndDate.value;
    this.item.releaseDate = new DisplayDate();
    this.item.accessStartDate = new DisplayDate();
    this.item.accessEndDate = new DisplayDate();
    this.item.createDate = new DisplayDate();
    this.item.postStartDate = new DisplayDate();
    this.item.postEndDate = new DisplayDate();
  };
}

class Post {
  items: PostProperty[] = [];
  officialUsers = computed(() => {
    if (myAttributes.myRoleSettings?.talkrooms.unrestrictedByLinkedOfficialUsers) return officialUsers.officialUsers;
    else
      return officialUsers.officialUsers.filter((user) =>
        myAttributes.linkedOfficialUserIds.includes(user.officialUserId)
      );
  });
  talkRoomId: string;
  pinnedPostId = '';
  fetchLoadingTalkroom = false;
  fetchLoadingOfficialUsers = computed(() => officialUsers.fetchLoading);
  fetchLoading = computed(() => this.fetchLoadingTalkroom && this.fetchLoadingOfficialUsers);
  postLoading = false;
  pinnedLoading = false;

  constructor(talkRoomId: string) {
    this.talkRoomId = talkRoomId;
    this.init();
  }

  init = async () => {
    this.fetchLoadingTalkroom = true;
    [this.items, this.pinnedPostId] = await Promise.all([this.getPost(), this.getPinnedPostId()]);
    this.fetchLoadingTalkroom = false;
  };

  getPost = async (): Promise<PostProperty[]> => {
    if (myAttributes.myRequestPermissions?.posts) {
      const result = await core.httpClient.get(`/admin/public/posts?talkRoomId=${this.talkRoomId}`);
      const _items = result.data as PostResponse[];
      return _items.map((item) => ({
        postId: item.postId,
        postUser: item.postUser,
        nickname: item.nickname,
        body: item.body.replace(/\r\n|\n|\r/gm, '<br>'),
        postedDate: new DisplayDate(item.postedDate),
        isOfficial: item.isOfficial,
        color: item.color,
        image: item.image,
        videoId: item.videoId,
      }));
    } else return [] as PostProperty[];
  };
  getPinnedPostId = async (): Promise<string> => {
    if (myAttributes.myRequestPermissions?.talkRooms) {
      const result = await core.httpClient.get(`/admin/public/talkRooms/pinnedPost?talkRoomId=${this.talkRoomId}`);
      const pinnedItem = result.data as PostResponse | undefined;
      return pinnedItem ? pinnedItem.postId : '';
    } else return '';
  };

  post = async (props: PostProps) => {
    this.postLoading = true;
    try {
      await core.httpClient.post('/admin/public/posts', props);
      this.items = await this.getPost();
    } finally {
      this.postLoading = false;
    }
  };
  deletePost = async (postId: string) => {
    this.postLoading = true;
    await core.httpClient.delete(`/admin/public/posts/${postId}?talkRoomId=${this.talkRoomId}`);
    this.items = this.items.filter((item) => postId !== item.postId);
    this.postLoading = false;
  };

  savePinnedPost = async (postId: string) => {
    const props = {
      talkRoomId: this.talkRoomId,
      postId,
    };
    try {
      await core.httpClient.post('/admin/public/talkRooms/pinnedPost', props);
      this.pinnedPostId = postId;
    } catch (e) {
      console.error(e);
    }
  };
  deletePinnedPost = async () => {
    try {
      await core.httpClient.delete(`/admin/public/talkRooms/pinnedPost?talkRoomId=${this.talkRoomId}`);
      this.pinnedPostId = '';
    } catch (e) {
      console.error(e);
    }
  };
  getCsvData = async () => {
    if (myAttributes.myRequestPermissions?.posts) {
      const result = await core.httpClient.get(`/admin/public/posts?talkRoomId=${this.talkRoomId}&withDeleted=true`);
      const _items = result.data as PostResponse[];
      return _items.map((item) => ({
        postId: item.postId,
        postUser: item.postUser,
        nickname: item.nickname,
        body: item.body.replace(/\r\n|\n|\r/gm, '<br>'),
        postedDate: new DisplayDate(item.postedDate).dateTime,
        isOfficial: item.isOfficial ? '○' : '',
        color: item.color,
        isDeleted: item.isDeleted ? '○' : '',
      }));
    } else return [];
  };
}

export const useTalkRooms = () => {
  const talkRooms = new TalkRooms();
  return { talkRooms: reactive(talkRooms) };
};

export const useTalkRoomMaster = () => {
  const talkRoomMaster = new TalkRoomMaster();
  return { talkRoomMaster: reactive(talkRoomMaster) };
};

export const useTalkRoom = (talkRoomId?: string) => {
  const talkRoom = new TalkRoom(talkRoomId);
  return { talkRoom: reactive(talkRoom) };
};

export const usePost = (talkRoomId: string) => {
  const post = new Post(talkRoomId);
  return { post: reactive(post) };
};
