










































































import { computed, defineComponent } from '@vue/composition-api';
import { useTalkRooms } from '@/admin/talkRoom';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import core from '@/admin/core';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'TalkRooms',
  components: {
    FcRoleLoading,
    FcRoleDeny,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('talkrooms'));

    const { talkRooms } = useTalkRooms();
    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const headers = [
      { text: 'タイトル', value: 'title' },
      { text: '公開日', value: 'releaseDate.date' },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];
    const hostingUrl = core.hostingUrl;
    const deleteRoom = async (talkRoomId: string) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      try {
        await talkRooms.delete(talkRoomId);
        notification.notify('削除しました。');
      } catch (error) {
        notification.error(error);
      }
    };
    return {
      pageTitle: 'トークルーム',
      myRoleSettings,
      headers,
      talkRooms,
      deleteRoom,
      hostingUrl,
    };
  },
});
